/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { forwardRef } from "react"
import { makeStyles } from "@material-ui/core"
import "../../assets/print/work-order-page.css"
import clsx from "clsx"
import HeaderPrint from "components/common/print/HeaderPrint"
import { formatDate } from "utils/form-data"
import EQPrintForm from "components/quotation-estimates/EQPrintFormDisplay"

const useStyles = makeStyles({
  logo: {
    width: "12mm",
  },
  normalFont: {
    fontSize: "9pt",
    verticalAlign: "top",
  },
  pageHeaderContent: {
    width: "180mm",
    display: "flex",
    alignItems: "center",
    fontWeight: "bolder",
    fontStyle: "italic",
  },
  tableTitle: {
    fontWeight: "bold",
    marginBottom: "2mm",
  },
  page: {
    fontSize: "10pt",
    lineHeight: 1.1,
    "& table": {
      textAlign: "left",
    },
    "& th, & td, & tr": {
      // Remove row space by default
      marginBottom: 0,
      paddingBottom: 0,
    },
    "& table.table-headleft th": {
      paddingRight: "5mm",
    },
    "& table.table-headleft td": {
      textAlign: "right",
    },
    "& #general td": {
      textAlign: "left",
    },
    "& .table-long": {
      "& thead > * > *": {
        borderTop: "0.3mm solid black",
        borderBottom: "0.3mm solid black",
        padding: "1.1mm 2.6mm",
      },
      "& tbody > * > *": {
        padding: "1.05mm 2.6mm",
      },
      "& tbody > *:last-child > *": {
        borderBottom: "0.3mm solid black",
      },
    },
    "& #left-list": {
      "& tbody > * > *": {
        padding: "1.05mm 2.6mm",
        fontSize: "9pt",
      },
      "& thead > * > *": {
        fontSize: "9pt",
      },
    },
    "& #item-table": {
      "& tbody > * > *": {
        padding: "1.05mm 2.6mm",
        fontSize: "9pt",
      },
      "& thead > * > *": {
        fontSize: "9pt",
      },
    },
    "& .table-handling": {
      paddingRight: "2.6mm",
      pageBreakInside: "avoid",
      marginBottom: "3mm",
    },
    "& .table-handling tbody": {
      pageBreakInside: "avoid",
    },
    "& .title": {
      marginTop: 0,
      fontSize: "inherit",
    },
  },
  totalCostsTable: {
    "& * > * > *": {
      padding: "1.1mm 2.6mm",
      textAlign: "right",
    },
    "& * > * > :nth-child(1)": {
      textAlign: "left",
    },
    "& tbody > *:last-child > *": {
      paddingBottom: "1.7mm",
    },
    "& tfoot > *:first-child > *": {
      borderTop: "0.3mm solid black",
      paddingTop: "1.7mm",
    },
  },
  marginTable: {
    "& * > * > *": {
      padding: "1.1mm 2.6mm",
      textAlign: "right",
    },
  },
  approvedBox: {
    border: "0.3mm solid black",
    height: "15.5mm",
  },
  noBorder: {
    border: "none",
    width: "100%",
    padding: 0,
    // margin: "-10mm",
  },
})

const WOPrintForm = forwardRef(
  (
    {
      data,
      estimateList,
      approver,
      className,
      companyInformation,
      customerData,
      workOrderSections = [],
      variant = "var1",
      reduceProductionHour = false,
    },
    ref
  ) => {
    const classes = useStyles()
    // const marketingHead = process.env.REACT_APP_MARKETING_HEAD
    // const generalManager = process.env.REACT_APP_GENERAL_MANAGER
    // const marketingDeptName =
    //   process.env.REACT_APP_MARKETING_DEPT_TITLE_NAME || "Marketing"
    // const generalManagerTitle =
    //   process.env.REACT_APP_GENERAL_TITLE_NAME || "General Manager"

    const uniqueArr = Array.from(
      new Set(
        estimateList.map(
          (item) => item.salesEstimate?.estQuot?.rfq?.projectName ?? "-"
        )
      )
    )
    const estNumbers = Array.from(
      new Set(
        estimateList
          .map((item) => item.salesEstimate?.estQuot?.estimationNumber || "")
          .filter((item) => item)
      )
    )

    const leftTable = (
      <table id="left-list">
        <tbody className={classes.normalFont}>
          <tr>
            <td>Customer:</td>
            <td>{customerData?.name}</td>
          </tr>
          <tr>
            <td>Project Name:</td>
            <td>{data?.projectName ?? uniqueArr.join(", ")}</td>
          </tr>
          <tr>
            <td>PO No:</td>
            <td>
              {estimateList
                .map((item) => item?.salesEstimate?.slsPurchaseOrder?.poNumber)
                .filter((item) => item)
                .join(", ")}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{data?.status}</td>
          </tr>
        </tbody>
      </table>
    )

    const estQuotNumbers = React.useMemo(() => {
      if (!data?.estimateList) return ""
      const arr = Array.from(
        new Set(
          data.estimateList.map((estList) => estList.estQuot?.estimationNumber)
        )
      )
      return (
        <ul style={{ listStyleType: "none" }}>
          {arr.map((item) => {
            return <li key={item}>{item}</li>
          })}
        </ul>
      )
    }, [data])

    const preparedBy = React.useMemo(() => {
      return (data.madeBy ?? "")
        .split(",")
        .map((p) => p.trim())
        .filter((p) => p)
    }, [data])

    return (
      <div
        ref={ref}
        className={clsx(classes.page, className, "print-container")}
        id="print-container"
      >
        <section
          className={clsx("chapter", "wo-print")}
          style={{ paddingRight: 4, paddingLeft: 4 }}
        >
          <HeaderPrint
            companyInformation={companyInformation}
            typeDocument="Internal"
          />

          <div id="recipent-header">
            <div id="recipent-list">
              <span className={classes.normalFont}>Kepada:</span>
              <p className={classes.normalFont}>
                {workOrderSections.map((item, id) => (
                  <React.Fragment key={id}>
                    Section {item.name}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>

            <div id="recipent-signatures">
              <table className="with-border" cellSpacing={0}>
                <thead>
                  <tr>
                    {approver.map((item, id) => (
                      <td key={id} className={classes.normalFont}>
                        {item.deptName}
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {approver.map((item, id) => (
                      <td key={id} className={classes.normalFont}>
                        {item.pic}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div id="wo-notice-container">
            <div id="wo-notice">
              <h1
                style={{
                  // textAlign: "left",
                  // marginTop: "-2em",
                  fontSize: "18pt",
                  textDecoration: "underline",
                  textTransform: "uppercase",
                }}
              >
                Work Order
              </h1>
              Diberitahukan kepada semua departemen terkait, bahwa dengan
              terbitnya work order ini, menyatakan bahwa project sebagai
              berikut:
              {leftTable}
            </div>
            <div id="wo-numbers" style={{ position: "relative" }}>
              <table style={{ position: "absolute", right: 0 }}>
                <tbody className={classes.normalFont}>
                  <tr>
                    <td>WO No:</td>
                    <td>{data.woNumber}</td>
                  </tr>
                  <tr>
                    <td>WO Date:</td>
                    <td>{formatDate(data.woDate)}</td>
                  </tr>
                  <tr>
                    <td>Est No:</td>
                    <td>{estNumbers.join()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <table id="item-table" className="with-border" cellSpacing={0}>
            <thead>
              <tr>
                <th
                  rowSpan="2"
                  className={classes.normalFont}
                  style={{ width: 30 }}
                >
                  No
                </th>

                <th
                  rowSpan="2"
                  className={classes.normalFont}
                  style={{ width: variant === "var1" ? 175 : 240 }}
                >
                  Product Name
                </th>
                <th
                  rowSpan="2"
                  className={classes.normalFont}
                  style={{ width: variant === "var1" ? 155 : 230 }}
                >
                  Product Code
                </th>
                {variant === "var1" ? (
                  <th
                    rowSpan="2"
                    className={classes.normalFont}
                    style={{ width: "30%" }}
                  >
                    Remark
                  </th>
                ) : null}
                <th colSpan="2" className={classes.normalFont}>
                  Due Date
                </th>
              </tr>
              <tr>
                <th className={classes.normalFont}>Delivery</th>
                <th className={classes.normalFont}>Schedule</th>
              </tr>
            </thead>
            <tbody>
              {estimateList.map((item, index) => {
                const { schedules } = item

                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td className={classes.normalFont}>{index + 1}</td>
                      <td
                        className={classes.normalFont}
                        style={{ textAlign: "left" }}
                      >
                        <strong className={classes.normalFont}>
                          {item?.description ??
                            item?.salesEstimate?.description ??
                            "-"}
                        </strong>
                      </td>
                      <td className={classes.normalFont}>{item?.mouldCode}</td>
                      {variant === "var1" ? (
                        <td
                          className={classes.normalFont}
                          style={{ textAlign: "left" }}
                        >
                          <p
                            style={{ whiteSpace: "pre-line", marginTop: "0" }}
                            className={classes.normalFont}
                          >
                            {item?.remark}
                          </p>
                        </td>
                      ) : null}
                      <td
                        rowSpan={variant === "var1" ? undefined : "2"}
                        style={
                          variant === "var1"
                            ? undefined
                            : { verticalAlign: "middle" }
                        }
                        className={classes.normalFont}
                      >
                        {formatDate(item?.dueDateDelivery, true)}
                      </td>
                      <td
                        style={
                          variant === "var1"
                            ? undefined
                            : { verticalAlign: "middle" }
                        }
                        rowSpan={variant === "var1" ? undefined : "2"}
                        className={classes.normalFont}
                      >
                        {!schedules.length ? "" : null}
                        {schedules.length > 0 ? (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {schedules.map((schedule, i) => (
                              <span key={`${schedule.name}${i}`}>
                                {schedule.name} :{" "}
                                {formatDate(schedule.date, true)}
                              </span>
                            ))}
                          </div>
                        ) : null}
                      </td>
                    </tr>
                    {variant === "var2" ? (
                      <tr>
                        <td />
                        <td
                          colSpan={2}
                          className={classes.normalFont}
                          style={{ textAlign: "left" }}
                        >
                          <p
                            style={{ whiteSpace: "pre-line", marginTop: "0" }}
                            className={classes.normalFont}
                          >
                            {item?.remark}
                          </p>
                        </td>
                        {/* <td /> */}
                        {/* <td /> */}
                      </tr>
                    ) : null}
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>

          <p>
            sudah dapat dimulai proses produksi sesuai dengan kondisi dan
            persyaratan yang telah disepakati kedua belah pihak.
          </p>

          <p>
            Demikianlah work order ini dibuat sebagai konfirmasi internal untuk
            segera dilakukan persiapan-persiapan produksi dari semua pihak
            terkait agar tercapai target on time delivery dan kualitas sesuai
            permintaan dan syarat-syarat dari pelanggan.
          </p>

          <div id="bottom-approval">
            <div id="approvals">
              <table>
                <tbody className={classes.normalFont}>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <p>Prepared,</p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>Knowledge,</p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>Acknowledge,</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="signature-space" />
                      <br />
                      <br />
                      {/* <br /> */}
                      {/* <br /> */}
                    </td>
                    <td>
                      <p className="signature-space" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          // gap: "2rem",
                        }}
                      >
                        {preparedBy.map((p, i) => {
                          return (
                            <p key={`${p}${i}`}>
                              <u>{p}</u>
                            </p>
                          )
                        })}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>
                        <u>{data?.approvedBy ?? "-"}</u>
                        <br />
                        {/* Departemen {marketingDeptName} */}
                      </p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>
                        <u>{data?.acknowledgeBy ?? "-"}</u>
                        <br />
                        {/* {generalManagerTitle} */}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table
              id="delivery-checked"
              cellSpacing={0}
              className="with-border"
            >
              <tbody className={classes.normalFont}>
                <tr>
                  <td
                    colSpan="2"
                    style={{ textAlign: "center", textTransform: "uppercase" }}
                  >
                    Delivery Checked
                  </td>
                </tr>
                <tr>
                  <td style={{ borderBottom: "none", width: "6em" }}>Date</td>
                  <td />
                </tr>
                <tr>
                  <td style={{ borderTop: "none" }}>Issued by</td>
                  <td />
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ height: "2rem", verticalAlign: "baseline" }}
                  >
                    <span>Remarks</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {estQuotNumbers}
          {variant === "var2"
            ? estimateList.map((estList) => {
                const { estQuot = {} } = estList?.salesEstimate || {}
                const { processCosts, ...otherItems } = estQuot
                const adjustedProcessCosts = processCosts.map((pc) => {
                  let { time } = pc

                  if (reduceProductionHour && estQuot.productionHourReducer)
                    time -= time * (estQuot.productionHourReducer / 100)
                  time = Math.floor(time)
                  return {
                    ...pc,
                    time,
                  }
                })
                return (
                  <div
                    key={estList.idWorkOrderEstimate}
                    style={{ pageBreakBefore: "always" }}
                  >
                    <EQPrintForm
                      data={{
                        processCosts: adjustedProcessCosts,
                        ...otherItems,
                      }}
                      companyInfo={{ data: companyInformation }}
                      printTarget="production"
                      customerData={estQuot?.rfq?.customer}
                      rateInCurr={estQuot?.rateIn}
                      rateOutCurr={estQuot?.rateOut}
                    />
                  </div>
                )
              })
            : null}
        </section>
      </div>
    )
  }
)

export default WOPrintForm
