import React, { forwardRef } from "react"
import clsx from "clsx"
import "../../assets/print/quotation-page.css"
import approved from "assets/approved.jpg"
import HeaderPrint from "components/common/print/HeaderPrint"
import { usePrintStyles } from "./constants"
import {
  useAlignStyles,
  useTableStyles,
} from "components/bill-of-material/BOMItemPrintStyles"
import { makeStyles } from "@material-ui/core"
import useEQPrintData from "./useEQPrintData"
import PrintCostTable from "./print/PrintConstTable"
import ApprovalTable from "./print/ApprovalTable"
import TotalCostSection from "./print/TotalCostSection"
import DocumentInfo from "./print/DocumentInfo"

// saanjaya
const num = (number) => parseFloat(number) || 0

const useStyles = makeStyles({
  noteContainer: {
    "& > * > img": {
      maxHeight: 200,
    },
    "& > img": {
      maxHeight: 200,
    },
  },
})

const EQPrintForm = forwardRef(
  (
    {
      className,
      data,
      customerData,
      companyInfo = {},
      rateInCurr,
      rateOutCurr,
      printTarget,
      margin,
      marginCurrencies = [],
      marginMarkiplier = null,
    },
    ref
  ) => {
    rateInCurr = rateInCurr ?? {}
    rateOutCurr = rateOutCurr ?? {}
    customerData = customerData ?? {}
    let companyInformation = {}

    const styles = useStyles()

    if ("data" in companyInfo) {
      companyInformation = companyInfo.data
    }

    const classes = usePrintStyles()
    const tableClasses = useTableStyles()
    const alignClasses = useAlignStyles()

    /* Compute material costs and display */

    const {
      additionalData,
      materialData,
      outsourcingData,
      processData,
      sparePartData,
      standardPartData,
    } = useEQPrintData({ data, printTarget, marginMarkiplier, margin })

    const MATERIAL_DATA = materialData.items

    const MATERIAL_COLUMNS = [
      "No",
      "Item Description",
      "Item No.",
      "Material Type",
      "Dimension",
      "Weight",
      "Qty",
      "Uom",
      "Unit Price",
      "Total",
      "Process",
      "Material + Process Cost",
    ]

    if (printTarget === "production") {
      MATERIAL_COLUMNS.splice(8, 2)
    }

    const alignRight = { style: { textAlign: "right" } }

    const MAT_COL_PROPS = [
      null,
      null,
      null,
      null,
      alignRight,
      alignRight,
      alignRight, // X, Y, Z
      alignRight,
      alignRight,
      alignRight, // weight, qty, unit price
      alignRight, // total
    ]

    const materialElement = (
      <PrintCostTable
        title="Material Cost"
        data={MATERIAL_DATA}
        columns={MATERIAL_COLUMNS}
        columnProps={MAT_COL_PROPS}
        handling={data.materialCostHandling}
        subtotalCost={materialData.totalCost}
        printTarget={printTarget}
        classes={classes}
        containerProps={{ style: { maxWidth: undefined } }}
      />
    )

    // Display process

    const PROCESS_DATA = processData.items
    const PROCESS_COLUMNS = [
      "No",
      "Item Description",
      // "Hour",
      "Quantity",
      "Material Process",
      "Uom",
      "Sub Total (Process)",
      "Unit Price",
      "Total",
    ]

    const PROCESS_COL_PROPS = [
      null,
      null,
      alignRight,
      alignRight,
      alignRight,
      alignRight,
      alignRight, // qty, unit price, total
    ]

    const processElement = (
      <PrintCostTable
        title="Process Cost"
        data={PROCESS_DATA}
        columns={PROCESS_COLUMNS}
        columnProps={PROCESS_COL_PROPS}
        handling={data.processCostHandling}
        subtotalCost={processData.totalCost}
        printTarget={printTarget}
        classes={classes}
        containerProps={{ style: { maxWidth: undefined } }}
      />
    )

    const ADDITIONAL_DATA = additionalData.items
    const OUTSOURCING_DATA = outsourcingData.items
    const ADDITIONAL_COLUMNS = [
      "No",
      "Item Description",
      "Quantity",
      "Uom",
      "Cost",
      "%",
      "Total",
    ]

    const OUTSOURCING_COLUMNS = [
      "No",
      "Item Description",
      "Quantity",
      "Uom",
      "Cost",
      "%",
      "Total",
    ]

    if (printTarget === "production") {
      MATERIAL_COLUMNS.splice(8, 2)
      PROCESS_COLUMNS.splice(5, 3)
      ADDITIONAL_COLUMNS.splice(4, 3)
      OUTSOURCING_COLUMNS.splice(4, 3)
    }

    const currentCheck = (data?.estQuotApproval?.edges ?? []).filter(
      (edge) => edge.node.approvalType === "CHECKED"
    )[0]
    const currentApprove = (data?.estQuotApproval?.edges ?? []).filter(
      (edge) => edge.node.approvalType === "APPROVED"
    )[0]

    const approvedImage = (
      <img
        src={approved}
        alt="approved"
        style={{
          width: "75px",
          height: "auto",
          position: "relative",
          top: "-14px",
          left: "8px",
        }}
      />
    )
    const approvalTable = (
      <ApprovalTable
        approvedImage={approvedImage}
        currentApprove={currentApprove}
        currentCheck={currentCheck}
        data={data}
        tableClasses={tableClasses}
      />
    )

    const ADDITIONAL_COL_PROPS = [
      null,
      null,
      alignRight,
      alignRight,
      alignRight, // qty, unit price, total
    ]

    const additionalElement = (
      <PrintCostTable
        title="Additional Process"
        data={ADDITIONAL_DATA}
        columns={ADDITIONAL_COLUMNS}
        columnProps={ADDITIONAL_COL_PROPS}
        handling={data.additionalCostHandling}
        subtotalCost={additionalData.totalCost}
        printTarget={printTarget}
        classes={classes}
      />
    )

    const standardPartElement = (
      <PrintCostTable
        title="Standard Part Cost"
        data={standardPartData.items}
        columnProps={ADDITIONAL_COL_PROPS}
        columns={ADDITIONAL_COLUMNS}
        handling={data.stsandardPartCostHandling}
        subtotalCost={standardPartData.totalCost}
        printTarget={printTarget}
        classes={classes}
      />
    )

    const sparePartElement = (
      <PrintCostTable
        title="Spare Part (Cutting Tool) Cost"
        data={sparePartData.items}
        columnProps={ADDITIONAL_COL_PROPS}
        columns={ADDITIONAL_COLUMNS}
        handling={data.stsandardPartCostHandling}
        subtotalCost={sparePartData.totalCost}
        printTarget={printTarget}
        classes={classes}
      />
    )

    const outsourcingElement = (
      <PrintCostTable
        title="Outsourcing Process"
        data={OUTSOURCING_DATA}
        columns={OUTSOURCING_COLUMNS}
        columnProps={ADDITIONAL_COL_PROPS}
        handling={data.outsourcingCostHandling}
        subtotalCost={outsourcingData.totalCost}
        printTarget={printTarget}
        classes={classes}
      />
    )

    /* Compute total cost */

    const rateInValue = data.rateInValue
    const rateOutValue = data.rateOutValue

    const totalProcess =
      processData.totalCost * (1 + num(data.processCostHandling) / 100)
    const totalMaterial =
      materialData.totalCost * (1 + num(data.materialCostHandling) / 100)
    const totalAdditional =
      additionalData.totalCost * (1 + num(data.additionalCostHandling) / 100)
    const totalOutsourcing =
      outsourcingData.totalCost * (1 + num(data.outsourcingCostHandling) / 100)
    const totalStandardPartCost =
      standardPartData.totalCost *
      (1 + num(data.standardPartCostHandling) / 100)
    const totalSparePartCost =
      sparePartData.totalCost * (1 + num(data.sparePartCostHandling) / 100)
    const totalCost =
      totalProcess +
      totalMaterial +
      totalAdditional +
      totalOutsourcing +
      totalStandardPartCost +
      totalSparePartCost

    const processPercentage = +((totalProcess / totalCost) * 100).toFixed(2)
    const materialPercentage = +((totalMaterial / totalCost) * 100).toFixed(2)
    const additionalPercentage = +((totalAdditional / totalCost) * 100).toFixed(
      2
    )
    const standardPartPercentage = +(
      (totalStandardPartCost / totalCost) *
      100
    ).toFixed(2)
    const sparePartPercentage = +(
      (totalSparePartCost / totalCost) *
      100
    ).toFixed(2)
    const outsourcingPercentage = +(
      (totalOutsourcing / totalCost) *
      100
    ).toFixed(2)

    return (
      <div
        className={clsx(classes.page, className, "print-container")}
        ref={ref}
      >
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <HeaderPrint
                  companyInformation={companyInformation}
                  typeDocument="Internal"
                />
              </td>
            </tr>
            <tr>
              <td>
                <section className={clsx("chapter", classes.page)}>
                  <h1
                    className="title"
                    style={{ textTransform: "uppercase", fontSize: "14pt" }}
                  >
                    Estimation for Quotation
                  </h1>

                  <DocumentInfo
                    customerData={customerData}
                    data={data}
                    rateInCurr={rateInCurr}
                    rateOutCurr={rateOutCurr}
                  />

                  {!["production"].includes(printTarget) ? (
                    <TotalCostSection
                      classes={classes}
                      rateInCurr={rateInCurr}
                      rateOutCurr={rateOutCurr}
                      margin={margin}
                      printTarget={printTarget}
                      rateInValue={rateInValue}
                      rateOutValue={rateOutValue}
                      totalAdditional={totalAdditional}
                      totalCost={totalCost}
                      totalMaterial={totalMaterial}
                      totalOutsourcing={totalOutsourcing}
                      totalProcess={totalProcess}
                      totalSparePart={totalSparePartCost}
                      totalStandardPart={totalStandardPartCost}
                      processPercentage={processPercentage}
                      materialPercentage={materialPercentage}
                      additionalPercentage={additionalPercentage}
                      standardPartPercentage={standardPartPercentage}
                      sparePartPercentage={sparePartPercentage}
                      outsourcingPercentage={outsourcingPercentage}
                      marginCurrencies={marginCurrencies}
                    />
                  ) : null}

                  {approvalTable}

                  {materialData.items.length > 0 && materialElement}
                  {standardPartData.items.length > 0 && standardPartElement}
                  {sparePartData.items.length > 0 && sparePartElement}
                  {additionalData.items.length > 0 && additionalElement}
                  {outsourcingData.items.length > 0 && outsourcingElement}
                  {processData.items.length > 0 && processElement}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "2mm",
                      marginBottom: "3mm",
                      flexDirection: "column",
                      marginRight: "2em",
                    }}
                  >
                    <div
                      style={{ width: "100%", pageBreakInside: "avoid" }}
                      className="fontSizeComment"
                    >
                      <p style={{ fontWeight: "bold" }}>Comment:</p>
                      <div
                        style={{ whiteSpace: "pre" }}
                        className={styles.noteContainer}
                        dangerouslySetInnerHTML={{
                          __html: `<style>p {margin: 0}</style> ${data.comment}`,
                        }}
                      />
                    </div>
                  </div>
                </section>
              </td>
            </tr>
            <tr>
              <td>
                <p className="page-footer" style={{ fontSize: 12 }}>
                  {data?.rfq?.rfqNumber}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
)

export default EQPrintForm
