/* eslint-disable react/prop-types */
import { Grid, TextField } from "@material-ui/core"
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import { transformProperty } from "utils/objects"
import InvoiceFormContext from "./InvoiceFormContex"
import { currencyOperation, getDefaultSettings } from "./utils"

const TotalCostSection = forwardRef(({ initialValue }, ref) => {
  const {
    productDescriptions,
    paymentPercentage,
    currencyRate,
    rateIn,
  } = useContext(InvoiceFormContext)

  const subTotal = useMemo(() => {
    if (!paymentPercentage)
      return productDescriptions.reduce(
        (a, b) => a + currencyOperation(+b.unitPrice, rateIn, currencyRate),
        0
      )
    return productDescriptions.reduce(
      (a, b) =>
        a +
        currencyOperation(+b.unitPrice, rateIn, currencyRate) *
          (0 + paymentPercentage / 100),
      0
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentPercentage, productDescriptions, currencyRate])

  const [discount, setDiscount] = useState(initialValue?.discount ?? 0)
  const [vat, setVat] = useState(initialValue?.vat ?? 0)
  const [pph23, setPph23] = useState(initialValue?.pph23 || 0)

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      const data = { discount, vat, pph23 }
      transformProperty(data, ["discount", "vat", "pph23"], parseInt)
      if (!data.discount || data.discount <= 0) delete data.discount
      if (!data.vat || data.vat <= 0) delete data.vat
      return data
    },
  }))

  const otherTaxBase = useMemo(() => {
    return Math.round((11 / vat) * (subTotal - discount))
  }, [subTotal, discount, vat])

  const taxAmount = useMemo(() => {
    return otherTaxBase * (vat / 100)
  }, [vat, otherTaxBase])

  const totalCost = useMemo(() => {
    const totalPph = (subTotal - discount) * (pph23 / 100)

    return subTotal - discount + taxAmount - totalPph
  }, [subTotal, discount, pph23, taxAmount])

  return (
    <Grid container>
      <Grid item md={3} style={{ marginRight: 0, marginLeft: "auto" }}>
        <TextField
          label="Sub Total"
          fullWidth
          margin="dense"
          disabled
          value={new Intl.NumberFormat().format(Math.ceil(subTotal))}
        />
        <TextField
          {...getDefaultSettings("discount", "Discount")}
          value={discount}
          type="number"
          onChange={(e) => setDiscount(e.target.value)}
        />

        <TextField
          margin="dense"
          label="Other Tax Base"
          fullWidth
          disabled
          value={new Intl.NumberFormat().format(otherTaxBase)}
        />

        <TextField
          {...getDefaultSettings("vat", "Vat (%)")}
          value={vat}
          type="number"
          onChange={(e) => setVat(e.target.value)}
        />

        <TextField
          {...getDefaultSettings("pph23", "Pph23 (%)")}
          value={pph23}
          type="number"
          onChange={(e) => setPph23(e.target.value)}
        />

        <TextField
          disabled
          {...getDefaultSettings("total", "Total")}
          value={new Intl.NumberFormat().format(Math.round(totalCost))}
        />
      </Grid>
    </Grid>
  )
})

export default TotalCostSection
