/* eslint-disable react/prop-types */
import React, { useMemo } from "react"
import { currencyOperation } from "./utils"

function TotalCostTable({ classes, data }) {
  const subTotal = useMemo(() => {
    const { paymentPercentage, productDescriptions } = data
    let amount = 0
    if (!paymentPercentage) {
      amount = productDescriptions.reduce(
        (a, b) =>
          a + currencyOperation(+b.unitPrice, data.rateIn, data.currencyRate),
        0
      )
    } else {
      amount = productDescriptions.reduce(
        (a, b) =>
          a +
          currencyOperation(+b.unitPrice, data.rateIn, data.currencyRate) *
            (0 + paymentPercentage / 100),
        0
      )
    }
    return Math.ceil(amount)
  }, [data])

  const otherTaxBase = React.useMemo(() => {
    return Math.round((11 / data.vat) * (subTotal - data.discount))
  }, [subTotal, data.discount, data.vat])

  const taxAmount = React.useMemo(() => {
    return otherTaxBase * (data.vat / 100)
  }, [data.vat, otherTaxBase])

  const totalCost = React.useMemo(() => {
    const totalPph = (subTotal - data.discount) * (data.pph23 / 100)

    return subTotal - data.discount + taxAmount - totalPph
  }, [data, subTotal, taxAmount])

  return (
    <table className={classes.sectionTotalTable}>
      <tbody>
        <tr>
          <th>Subtotal</th>
          <th>{new Intl.NumberFormat().format(subTotal)}</th>
        </tr>
        <tr>
          <th>Discount</th>
          <th>{new Intl.NumberFormat().format(data.discount ?? 0)}</th>
        </tr>
        <tr>
          <th>Other Tax Base</th>
          <th>{new Intl.NumberFormat().format(otherTaxBase)}</th>
        </tr>
        <tr>
          <th>VAT ({data.vat ?? 0}%)</th>
          <th>{new Intl.NumberFormat().format(Math.round(taxAmount))}</th>
        </tr>
        <tr>
          <th>Pph23 ({data.pph23 ?? 0}%)</th>
          <th>
            {new Intl.NumberFormat().format(
              Math.ceil(
                (subTotal - data.discount) * (0 + data?.pph23 / 100 ?? 0)
              )
            )}
          </th>
        </tr>

        <tr>
          <th>Total</th>
          <th>{new Intl.NumberFormat().format(Math.round(totalCost))}</th>
        </tr>
      </tbody>
    </table>
  )
}

export default TotalCostTable
